<template>
  <div class="container with-sidebar">
    <Sidebar />
    <div>
      <h1>Versteppung</h1>
      <p>
        Unser Grundstück liegt in Andalusien im Valle de los Pedroches. Dieser Bereich ist
        Teil des uralten spanischen Agroforstsystems namens <em>Dehesa</em>. Die Dehesa
        ist eine Art Eichensavanne mit verschiedenen Arten von Eichen, die die Landschaft dominieren
        während Gräser und andere Pflanzenarten die Zwischenräume füllen.
      </p>
      <p>
        Leider stirbt die Dehesa aufgrund vieler verschiedener Faktoren, darunter
        Über- und Unterweidung. Dadurch ist der Boden jetzt verdichtet, hält kein Wasser
        mehr, aufgrund von sehr wenig organischem Material nimmt die Vielfalt der Pflanzenarten ab
        und da es in der heißen Jahreszeit 6 Monate lang nicht regnet ist es eine herausfordernde Situation.
      </p>
      <p>
        Unsere Temperaturen im Winter liegen bei etwa -10° Celsius und im Sommer können wir eine
        Umgebungstemperatur von 45° Celsius im Schatten haben. Im Herbst, Winter und Frühling regnet es und wir
        bekommen etwa 500 mm bis 600 mm Regen pro Jahr. Ältere Menschen berichten das in der Vergangenheit
        es im Sommer Gewitter gab, aber das ist seit ein paar Jahrzehnten nicht mehr der Fall.
      </p>
      <p>
        Die spanische Regierung hat Informationen zum aktuellen Stand über
        die Wüstengefahr in Spanien veröffentlicht. Bitte sehen Sie sich ihre Seite an:
        <a href="https://www.miteco.gob.es/es/biodiversidad/temas/desertificacion-restauracion/lucha-contra-la-desertificacion/lch_espana.aspx"
          target="_blank">La desertificación en España</a> (Spanish)
      </p>

      <p>
        Im Oktober 2017, mit den noch vorhandenen Tieren des Vorbesitzers, sah das Grundstück so aus:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2017-10-01_18-33-44.jpg">
        <img src="../../assets/images/2017-10-01_18-33-44.jpg">
      </picture>

      <p>
        Nach etwas Regen im November 2017 tauchte ein bisschen kurzes Gras auf und wurde sofort gefressen:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2017-11-16_13-03-53.jpg">
        <img src="../../assets/images/2017-11-16_13-03-53.jpg">
      </picture>

      <p>
        Mit Hilfe vieler Tiere, die auf andere Weise gehalten wurden, haben wir es geschafft auf den Weg der
        Restaurierung zu kommen. Dieses Bild vom April 2022 zeigt es:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-04-19_12-21-42.jpg">
        <img src="../../assets/images/2022-04-19_12-21-42.jpg">
      </picture>

      <p>
        Anders als früher sind wir jetzt (Juni 2022) in der Lage, den Boden mit Biomasse bedeckt zu halten, um den Boden
        stattdessen am Leben zu erhalten als jedes Jahr von der Sonne alles töten zu lassen:
      </p>
      <picture>
        <source media="(max-width: 500px)" srcset="../../assets/images/sm-2022-06-02_14-05-29.jpg">
        <img src="../../assets/images/2022-06-02_14-05-29.jpg">
      </picture>

      <YouTubeLink />
    </div>
  </div>
</template>

<script>
import Sidebar from '@/views/concept/Sidebar.vue';
import YouTubeLink from '@/components/YouTubeLink.vue';

export default {
  name: "DesertificationDE",
  components: {
    Sidebar,
    YouTubeLink
  },
};
</script>
